import { sygnet } from './sygnet'
import { logo } from './logo'
import { logoNegative } from './logo-negative'
import { sx11 } from './sx11'
import { fx1000 } from './fx1000'
import { fx1000s } from './fx1000s'
import { fx6000 } from './fx6000'

import {
  cilApplicationsSettings,
  cilSpeedometer,
  cilSun,
  cilMoon,
  cilMemory,
  cilAlarm,
  cilHome,
  cilText,
  cilHeadphones,
  cilHealing,
  cilHighlighter,
  cilHighligt,
  cibRollupJs,
  cibRoundcube,
  cilBatteryAlert,
  cibSpreaker,
  cilPencil,
  cilTrash,
  cibAddthis,
  cilPlus,
  cilScrubber,
  cilAccountLogout,
  cilLockUnlocked,
  cibVerizon,
  cilSettings,
  cilBluetooth,
  cilLan,
  cilWifiSignal0,
  cilWifiSignal4,
  cilShieldAlt,
  cilZoomIn,
  cilUser,
  cilOptions,
  cilObjectGroup,
  cilCameraControl,
  cilLockLocked,
  cilInfo,
  cilFindInPage,
  cibCodesandbox,
  cilFilter,
  cilAvTimer,
  cilDataTransferDown,
  cilArrowCircleLeft,
  cilMinus,
} from '@coreui/icons'

export const icons = Object.assign({}, {
  sygnet,
  logo,
  logoNegative,
  sx11,
  fx1000,
  fx1000s,
  fx6000
}, {
  cilApplicationsSettings,
  cilSpeedometer,
  cilSun,
  cilMoon,
  cilMemory,
  cilAlarm,
  cilAccountLogout,
  cilHome,
  cilText,
  cilHeadphones,
  cilHealing,
  cilHighlighter,
  cilHighligt,
  cibRollupJs,
  cibRoundcube,
  cilBatteryAlert,
  cibSpreaker,
  cilPencil,
  cilTrash,
  cibAddthis,
  cilPlus,
  cilScrubber,
  cilLockUnlocked,
  cibVerizon,
  cilSettings,
  cilBluetooth,
  cilLan,
  cilWifiSignal0,
  cilWifiSignal4,
  cilShieldAlt,
  cilZoomIn,
  cilUser,
  cilOptions,
  cilObjectGroup,
  cilCameraControl,
  cilLockLocked,
  cilInfo,
  cilFindInPage,
  cibCodesandbox,
  cilFilter,
  cilAvTimer,
  cilDataTransferDown,
  cilArrowCircleLeft,
  cilMinus
})
